import React, { useEffect, useMemo, useState } from 'react';
import { Router } from '@reach/router';
import moment from 'moment';

import MainTimeline from './MainTimeline';
import DateTimeline from './DateTimeline';
import ShowTimeline from './ShowTimeline';
import { keyBy } from 'lodash';
import { replaceShowSlugWithObj } from '../../utils/showUtils';

interface TimelineWrapperProps {
  data: {
    album: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Album;
            slug: string;
          };
        };
      }[];
    };
    liveRecording: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const TimelineWrapper: React.FC<TimelineWrapperProps> = props => {
  const {
    data: {
      album,
      liveRecording,
      liveVideo,
      photo,
      polaroid,
      poster,
      text,
      show,
    },
  } = props;

  const [allContent, setAllContent] = useState<Common.ContentNode[]>([]);
  const [contentByType, setContentByType] = useState<Collections.ContentByType>(
    {
      live_recording: [],
      live_video: [],
      photo: [],
      polaroid: [],
      poster: [],
      text: [],
    }
  );
  const [contentByCity, setContentByCity] = useState<Collections.ContentByCity>(
    {}
  );

  const showsKeyed = useMemo(() => {
    const shows = show.edges.map(edge => edge.node.childMdx);
    return keyBy(shows, 'slug');
  }, [show]);

  useEffect(() => {
    liveRecording.edges = replaceShowSlugWithObj(
      liveRecording.edges,
      showsKeyed
    );
    liveVideo.edges = replaceShowSlugWithObj(liveVideo.edges, showsKeyed);
    photo.edges = replaceShowSlugWithObj(photo.edges, showsKeyed);
    polaroid.edges = replaceShowSlugWithObj(polaroid.edges, showsKeyed);
    poster.edges = replaceShowSlugWithObj(poster.edges, showsKeyed);
    text.edges = replaceShowSlugWithObj(text.edges, showsKeyed);

    setContentByType({
      live_recording: liveRecording?.edges.map(edge => edge.node.childMdx),
      live_video: liveVideo?.edges.map(edge => edge.node.childMdx),
      photo: photo?.edges.map(edge => edge.node.childMdx),
      polaroid: polaroid?.edges.map(edge => edge.node.childMdx),
      poster: poster?.edges.map(edge => edge.node.childMdx),
      text: text?.edges.map(edge => edge.node.childMdx),
    });

    const all: Common.ContentNode[] = [];
    all.push(...(liveVideo?.edges ?? []));
    all.push(...(liveRecording?.edges ?? []));
    all.push(...(photo?.edges ?? []));
    all.push(...(polaroid?.edges ?? []));
    all.push(...(poster?.edges ?? []));
    all.push(...(text?.edges ?? []));
    setAllContent(all);
  }, [liveRecording, liveVideo, photo, polaroid, poster, showsKeyed, text]);

  const datesShowsObj = useMemo(() => {
    const datesShowsObj: Common.DatesShowsObj = {};

    // Do one pass to populate empty arrays
    for (const show of Object.values(showsKeyed)) {
      const date = moment(show.frontmatter.date);
      const yearObj = datesShowsObj[date.year()] ?? {};
      const monthObj = yearObj[date.month()] ?? {};
      const dayObj = monthObj[date.date()] ?? {};
      const showsArray = dayObj[show.slug] ?? [];

      dayObj[show.slug] = showsArray;
      monthObj[date.date()] = dayObj;
      yearObj[date.month()] = monthObj;
      datesShowsObj[date.year()] = yearObj;
    }

    for (const media of allContent) {
      const {
        node: { childMdx },
      } = media;

      if (typeof childMdx.frontmatter.show.slug === 'string') {
        const linkedShow = showsKeyed[childMdx.frontmatter.show.slug] ?? null;

        if (linkedShow === null) {
          console.warn(`Invalid linked show for ${childMdx.frontmatter.title}`);
          continue;
        }
      }

      const date = moment(childMdx.frontmatter.show.date);

      const yearObj = datesShowsObj[date.year()] ?? {};
      const monthObj = yearObj[date.month()] ?? {};
      const dayObj = monthObj[date.date()] ?? {};
      const showsArray = dayObj[childMdx.frontmatter.show.slug] ?? [];
      showsArray.push(childMdx);

      dayObj[childMdx.frontmatter.show.slug] = showsArray;
      monthObj[date.date()] = dayObj;
      yearObj[date.month()] = monthObj;
      datesShowsObj[date.year()] = yearObj;
    }

    return datesShowsObj;
  }, [allContent, showsKeyed]);

  useEffect(() => {
    const byCity: { [city: string]: Common.ContentChildMdx[] } = {};
    allContent.forEach(edge => {
      if (!byCity.hasOwnProperty(edge.node.childMdx.frontmatter.show.city)) {
        byCity[edge.node.childMdx.frontmatter.show.city] = [];
      }
      byCity[edge.node.childMdx.frontmatter.show.city].push(edge.node.childMdx);
    });
    setContentByCity(byCity);
  }, [allContent]);

  return (
    <Router basepath="timeline">
      <MainTimeline path="/" albums={album} datesShowsObj={datesShowsObj} />
      <DateTimeline
        path=":date"
        datesShowsObj={datesShowsObj}
        showsKeyed={showsKeyed}
      />
      <ShowTimeline
        path=":date/:show"
        datesShowsObj={datesShowsObj}
        showsKeyed={showsKeyed}
        contentByType={contentByType}
        contentByCity={contentByCity}
      />
    </Router>
  );
};

export default TimelineWrapper;
