import React, { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { CONTENT_TYPE } from '../../constants';
import LiveRecording from '../content_blocks/LiveRecording';
import LiveVideo from '../content_blocks/LiveVideo';
import Photo from '../content_blocks/Photo';
import Polaroid from '../content_blocks/Polaroid';
import Poster from '../content_blocks/Poster';
import borderUrl from '../../assets/images/related_tape.svg';

const StyledWrapper = styled.div`
  grid-area: related;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledBorder = styled.div`
  display: inline-block;
  ${props => props.theme.borderMask(borderUrl)};
  margin-bottom: 1rem;
`;

const StyledText = styled.div`
  background: ${props => props.theme.white};
  padding: 0.7rem 0.9rem 0.4rem;
  font-size: 0.625rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(borderUrl)};
`;

const StyledItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
`;

interface RelatedProps {
  content: Common.ContentChildMdx;
  setContent: Dispatch<SetStateAction<Common.ContentChildMdx | null>>;
  contentByType: {
    live_recording: Common.ContentChildMdx[];
    live_video: Common.ContentChildMdx[];
    photo: Common.ContentChildMdx[];
    polaroid: Common.ContentChildMdx[];
    poster: Common.ContentChildMdx[];
    text: Common.ContentChildMdx[];
  };
  contentByCity: {
    [city: string]: Common.ContentChildMdx[];
  };
}

const Related: React.FC<RelatedProps> = function (props) {
  const { content, setContent, contentByType, contentByCity } = props;

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleClick = useCallback(
    (blockContent: Common.ContentChildMdx) => {
      if (setContent) {
        if (
          blockContent.frontmatter.content_type ==
            CONTENT_TYPE.LIVE_RECORDING ||
          blockContent.frontmatter.content_type == CONTENT_TYPE.LIVE_VIDEO ||
          blockContent.frontmatter.content_type == CONTENT_TYPE.POSTER ||
          blockContent.frontmatter.content_type == CONTENT_TYPE.PHOTO ||
          blockContent.frontmatter.content_type == CONTENT_TYPE.POLAROID
        ) {
          window.history.pushState(
            null,
            document.title,
            `?content=${blockContent.slug}`
          );
          setContent(blockContent);
        }
      }
    },
    [setContent]
  );

  const renderBlock = (blockContent: Common.ContentChildMdx) => {
    switch (blockContent.frontmatter.content_type) {
      case CONTENT_TYPE.LIVE_RECORDING:
        <LiveRecording content={blockContent.frontmatter} />;
      case CONTENT_TYPE.LIVE_VIDEO:
        return <LiveVideo content={blockContent.frontmatter} />;
      case CONTENT_TYPE.PHOTO:
        return <Photo content={blockContent.frontmatter} />;
      case CONTENT_TYPE.POLAROID:
        return <Polaroid content={blockContent.frontmatter} />;
      case CONTENT_TYPE.POSTER:
        return <Poster content={blockContent.frontmatter} />;
    }
  };

  const renderRelated = useCallback(() => {
    const relatedItems: Common.ContentChildMdx[] = [];

    // Get 1 from same city
    const sameCity = contentByCity[content.frontmatter.show.city]
      .filter(item => item != content)
      .sort(() => Math.random() - 0.5);
    if (sameCity.length >= 1) {
      relatedItems.push(sameCity[0]);
    }

    // Get 1 or 2 of same type
    const sameType = contentByType[content.frontmatter.content_type]
      .filter(item => {
        if (relatedItems.length == 1) {
          return item != relatedItems[0] && item != content;
        } else {
          return item != content;
        }
      })
      .sort(() => Math.random() - 0.5);

    while (relatedItems.length < 2 && sameType.length > 0) {
      relatedItems.push(sameType[0]);
      sameType.shift();
    }

    return relatedItems.map(item => (
      <div key={item.id} onClick={() => handleClick(item)}>
        {renderBlock(item)}
      </div>
    ));
  }, [content, contentByCity, contentByType, handleClick]);

  return (
    <StyledWrapper onClick={handleMouseDown}>
      <StyledBorder>
        <StyledText>Related</StyledText>
      </StyledBorder>
      <StyledItems>{renderRelated()}</StyledItems>
    </StyledWrapper>
  );
};

export default Related;
