import React from 'react';
import styled from 'styled-components';
import HyvorTalk from 'hyvor-talk-react';

import { HYVOR_TALK_WEBSITE_ID } from '../../constants';

import borderUrl from '../../assets/images/disco_embed_border.svg';

const StyledBorder = styled.div`
  grid-area: comments;
  align-self: start;
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledMask = styled.div`
  position: relative;
  max-height: calc(100vh - 230px);
  padding: 1rem;
  background: ${props => props.theme.white};
  overflow-y: scroll;
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    max-height: initial;
  }

  iframe {
    height: 100%;
  }
`;

interface CommentsProps {
  slug: string;
  className?: string;
  handleMouseDown: React.MouseEventHandler;
}

const Comments: React.FC<CommentsProps> = props => {
  const { slug, className, handleMouseDown } = props;

  return (
    <StyledBorder className={className} onClick={handleMouseDown}>
      <StyledMask>
        <HyvorTalk.Embed websiteId={HYVOR_TALK_WEBSITE_ID} id={slug} />
      </StyledMask>
    </StyledBorder>
  );
};

export default Comments;
