import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import getRandomInt from '../utils/getRandomInt';

export default function useRandomBgColor(): void {
  const theme = useTheme();
  const bgColor = useMemo(() => {
    return theme[`background${getRandomInt(1, 5)}`];
  }, [theme]);

  // Shuffle items and set bg color
  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, []);
}
