import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import borderUrl from '../../assets/images/disco_embed_border.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBorder = styled.div`
  ${props => props.theme.borderMask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 100%;
  }
`;

type StyledMaskProps = {
  $width?: number;
  $height?: number;
};

const StyledMask = styled.div<StyledMaskProps>`
  position: relative;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};
  ${props =>
    props.$width && props.$height && 16 / 9 > props.$width / props.$height
      ? css`
          width: ${props.$width}px;
          height: calc(${props.$width}px / (16 / 9));
          max-height: ${props.$height}px;
        `
      : css`
          height: ${props.$height}px;
          width: calc(${props.$height}px * (16 / 9));
          max-width: ${props.$width}px;
        `}

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: calc(9 / 16 * ${props => props.$width}px);
    width: 100%;
  }

  iframe {
    position: absolute;
    top: -1%;
    left: -1%;
    height: 102%;
    width: 102%;
  }
`;

const StyledLoading = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
`;

interface LiveVideoDetailsProps {
  videoId: string;
  dimensions?: {
    width: number;
    height: number;
  };
}

const LiveVideoDetails: React.FC<LiveVideoDetailsProps> = props => {
  const { videoId, dimensions } = props;

  const iframe = useRef<HTMLIFrameElement | null>(null);
  const [loading, setLoading] = useState(true);

  const iframeOnLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    const current = iframe.current;
    current?.addEventListener('load', iframeOnLoad);

    return () => {
      current?.removeEventListener('load', iframeOnLoad);
    };
  }, []);

  return (
    <StyledContainer>
      <StyledBorder>
        <StyledMask $width={dimensions?.width} $height={dimensions?.height}>
          {loading && <StyledLoading>Loading...</StyledLoading>}
          <iframe
            ref={iframe}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </StyledMask>
      </StyledBorder>
    </StyledContainer>
  );
};

export default LiveVideoDetails;
