import React from 'react';
import styled, { css } from 'styled-components';
import Img, { GatsbyImageProps } from 'gatsby-image';

import borderUrl from '../../assets/images/gallery_image_border.svg';

const StyledBorder = styled.div`
  display: inline-block;
  position: relative;
  place-self: center;
  ${props => props.theme.borderMask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 100%;
  }
`;

type StyledImgProps = {
  $aspectRatio: number;
  $width?: number;
  $height?: number;
};

const StyledImg = styled(Img)<StyledImgProps>`
  ${props =>
    props.$width &&
    props.$height &&
    props.$aspectRatio > props.$width / props.$height
      ? css`
          width: ${props.$width}px;
          height: calc(${props.$width}px / ${props.$aspectRatio});
          max-height: ${props.$height}px;
        `
      : css`
          height: ${props.$height}px;
          width: calc(${props.$height}px * ${props.$aspectRatio});
          max-width: ${props.$width}px;
        `}
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    width: 100%;
  }
`;

interface ImageProps {
  image: {
    childImageSharp: GatsbyImageProps;
  };
  dimensions?: {
    width: number;
    height: number;
  };
}

const Image: React.FC<ImageProps> = props => {
  const { image, dimensions } = props;

  return (
    <StyledBorder>
      <StyledImg
        fluid={{ ...image.childImageSharp.fluid }}
        $aspectRatio={image.childImageSharp.fluid.aspectRatio}
        $width={dimensions?.width}
        $height={dimensions?.height}
      />
    </StyledBorder>
  );
};

export default Image;
