import React, { useLayoutEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';
import TimelineWrapper from '../components/timeline/TimelineWrapper';

interface TimelinePageProps extends PageProps {
  data: {
    album: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Album;
            slug: string;
          };
        };
      }[];
    };
    liveRecording: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const TimelinePage: React.FC<TimelinePageProps> = function (props) {
  const { data, location } = props;

  const [shouldRender, setShouldRender] = useState(false);

  useLayoutEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setShouldRender(true);
  }, []);

  return (
    <Layout currPage={location.pathname}>
      <SEO title="Timeline | Courtney Barnett" />
      {shouldRender && <TimelineWrapper data={data} />}
    </Layout>
  );
};

export default TimelinePage;

export const pageQuery = graphql`
  query timelineQuery {
    album: allFile(filter: { sourceInstanceName: { eq: "album" } }) {
      ...Album
    }
    liveRecording: allFile(
      filter: { sourceInstanceName: { eq: "live_recording" } }
    ) {
      ...LiveRecording
    }
    liveVideo: allFile(filter: { sourceInstanceName: { eq: "live_video" } }) {
      ...LiveVideo
    }
    photo: allFile(filter: { sourceInstanceName: { eq: "photo" } }) {
      ...Photo
    }
    polaroid: allFile(filter: { sourceInstanceName: { eq: "polaroid" } }) {
      ...Polaroid
    }
    poster: allFile(filter: { sourceInstanceName: { eq: "poster" } }) {
      ...Poster
    }
    text: allFile(filter: { sourceInstanceName: { eq: "text" } }) {
      ...Text
    }
    show: allFile(filter: { sourceInstanceName: { eq: "show" } }) {
      ...Show
    }
  }
`;
