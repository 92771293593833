import React, { useState } from 'react';
import styled from 'styled-components';

import Details from '../details/Details';
import WallObject from '../wall/WallObject';
import HighlightedItem from './HighlightedItem';
import DragScrollWrapper from '../shared/DragScrollWrapper';
import useContentFromUrl from '../../hooks/useContentFromUrl';

const StyledDragScrollWrapper = styled(DragScrollWrapper)`
  display: grid;
  grid-template-columns: auto 70vw auto;
  gap: 1rem;
  align-items: center;
  min-height: calc(100vh - 60px);
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: 60px 0 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

type StyledWallProps = {
  $isLeft?: boolean;
};

const StyledWall = styled.div<StyledWallProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => (props.$isLeft ? `flex-end` : `flex-start`)};
  align-items: center;
  width: 75vw;

  @media (max-width: ${props => props.theme.breakSmall}) {
    writing-mode: vertical-lr;
    width: auto;
    height: 200vh;
    justify-content: center;
  }
`;

const StyledWallObject = styled(WallObject)`
  writing-mode: horizontal-tb;
`;

interface HighlightsWallProps {
  content: Common.ContentNode[];
  highlightedItem: Collections.LiveVideo | null;
  contentByType: {
    live_recording: Common.ContentChildMdx[];
    live_video: Common.ContentChildMdx[];
    photo: Common.ContentChildMdx[];
    polaroid: Common.ContentChildMdx[];
    poster: Common.ContentChildMdx[];
    text: Common.ContentChildMdx[];
  };
  contentByCity: {
    [city: string]: Common.ContentChildMdx[];
  };
}

const HighlightsWall: React.FC<HighlightsWallProps> = props => {
  const {
    content: propsContent,
    highlightedItem,
    contentByType,
    contentByCity,
  } = props;

  const halfLength = Math.floor(propsContent.length / 2);
  const content = {
    left: propsContent.slice(0, halfLength),
    right: propsContent.slice(halfLength, propsContent.length),
  };

  const [currContent, setCurrContent] = useState<Common.ContentChildMdx | null>(
    null
  );

  useContentFromUrl(propsContent, setCurrContent);

  const onCloseDetailsClick = () => {
    window.history.pushState(
      null,
      document.title,
      window.location.href.split('?')[0]
    );
    setCurrContent(null);
  };

  return (
    <StyledDragScrollWrapper isWall>
      <StyledWall $isLeft>
        {content.left.map(edge => (
          <StyledWallObject
            key={edge.node.childMdx.id}
            content={edge.node.childMdx}
            setContent={setCurrContent}
          />
        ))}
      </StyledWall>
      {highlightedItem && <HighlightedItem content={highlightedItem} />}
      <StyledWall>
        {content.right.map(edge => (
          <StyledWallObject
            key={edge.node.childMdx.id}
            content={edge.node.childMdx}
            setContent={setCurrContent}
          />
        ))}
      </StyledWall>
      {currContent && (
        <Details
          content={currContent}
          onCloseClick={onCloseDetailsClick}
          contentByType={contentByType}
          contentByCity={contentByCity}
          setContent={setCurrContent}
        />
      )}
    </StyledDragScrollWrapper>
  );
};

export default HighlightsWall;
