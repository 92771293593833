import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Details from '../details/Details';
import WallObject from './WallObject';
import DragScrollWrapper from '../shared/DragScrollWrapper';
import Loader from '../shared/Loader';
import useContentFromUrl from '../../hooks/useContentFromUrl';

const StyledDragScrollWrapper = styled(DragScrollWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 600vw;
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: 60px 0 0;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    flex-direction: column;
    align-content: flex-start;
    height: 600vh;
  }
`;

interface WallProps {
  content: Common.ContentNode[];
  contentByType: {
    live_recording: Common.ContentChildMdx[];
    live_video: Common.ContentChildMdx[];
    photo: Common.ContentChildMdx[];
    polaroid: Common.ContentChildMdx[];
    poster: Common.ContentChildMdx[];
    text: Common.ContentChildMdx[];
  };
  contentByCity: {
    [city: string]: Common.ContentChildMdx[];
  };
}

const Wall: React.FC<WallProps> = props => {
  const { content, contentByType, contentByCity } = props;

  const [loading, setLoading] = useState(true);
  const [currContent, setCurrContent] = useState<Common.ContentChildMdx | null>(
    null
  );

  useEffect(() => {
    if (content.length === 0) {
      return;
    }

    // Scroll to center of page
    const containerRect = document
      .getElementById('container')
      ?.getBoundingClientRect();

    requestAnimationFrame(() => {
      if (containerRect) {
        window.scrollTo(
          containerRect.width / 2 - window.innerWidth / 2,
          containerRect.height / 2 - window.innerHeight / 2
        );
      }
    });

    if (content.length > 0) {
      setLoading(false);
    }
  }, [content]);

  useContentFromUrl(content, setCurrContent);

  const onCloseDetailsClick = () => {
    window.history.pushState(
      null,
      document.title,
      window.location.href.split('?')[0]
    );
    setCurrContent(null);
  };

  return (
    <StyledDragScrollWrapper isWall>
      {content.map(edge => (
        <WallObject
          key={edge.node.childMdx.id}
          content={edge.node.childMdx}
          setContent={setCurrContent}
        />
      ))}
      {currContent && (
        <Details
          content={currContent}
          onCloseClick={onCloseDetailsClick}
          contentByType={contentByType}
          contentByCity={contentByCity}
          setContent={setCurrContent}
        />
      )}
      <Loader loading={loading} />
    </StyledDragScrollWrapper>
  );
};

export default Wall;
