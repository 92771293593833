import React, { useCallback, useEffect, useState } from 'react';
import { PageProps, navigate, Link } from 'gatsby';
import styled, { css } from 'styled-components';
import moment from 'moment';

import DragScrollWrapper from '../shared/DragScrollWrapper';
import WallObject from '../wall/WallObject';
import Details from '../details/Details';

import useRandomBgColor from '../../hooks/useRandomBgColor';

import border1Url from '../../assets/images/timeline_block_1.svg';
import string, {
  ReactComponent as Line,
} from '../../assets/images/timeline_line.svg';
import { ReactComponent as Arrow } from '../../assets/images/timeline_arrow.svg';

const StyledContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr;
  width: 100%;
  height: calc(100vh - 50px);
  margin-bottom: 50px;
  overflow-y: auto;

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 100vh;
    margin: 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const StyledBackButton = styled.div`
  margin-top: 2rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: calc(50px + 1rem);
  }
`;

const StyledBackLink = styled(Link)`
  padding: 0.5rem 0.8rem 0.3rem;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  font-size: 0.625rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
`;

const StyledControlsSection = styled.div`
  position: relative;
`;

const StyledTimelineControls = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 2rem 1rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 1rem;
  }
`;

const StyledHeadings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  text-align: center;

  > *:last-child {
    margin-top: 0.2rem;
  }
`;

const StyledBorder = styled.div`
  display: inline-block;
  ${props => props.theme.borderMask(border1Url)};
`;

const StyledShowName = styled.div`
  padding: 0.8rem 1.2rem 0.5rem;
  background: ${props => props.theme.white};
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
`;

const StyledShowDate = styled.div`
  padding: 0.5rem 0.8rem 0.3rem;
  background: ${props => props.theme.white};
  font-size: 0.625rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
`;

type StyledArrowProps = {
  $isLeft?: boolean;
};

const StyledArrow = styled(Arrow)<StyledArrowProps>`
  width: 2rem;
  cursor: pointer;
  ${props =>
    props.$isLeft &&
    css`
      transform: scaleX(-1);
      justify-self: end;
    `};

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 1.5rem;
  }
`;

const StyledDragScrollWrapper = styled(DragScrollWrapper)`
  overflow-x: scroll;
`;

const StyledScrollableBox = styled.div`
  display: inline-flex;
  justify-content: center;
  min-width: 100%;
  margin-bottom: 1rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLine = styled(Line)`
  position: absolute;
  bottom: -0.4rem;
  width: 100%;
  stroke-width: 2px;

  @media (max-width: ${props => props.theme.breakLarge}) {
    stroke-width: 3px;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    stroke-width: 4px;
  }
`;

interface ShowObj {
  show: Collections.Show;
  slug: string;
  content: (
    | Collections.LiveRecording
    | Collections.LiveVideo
    | Collections.Photo
    | Collections.Polaroid
    | Collections.Poster
    | Collections.Text
  )[];
}

interface ShowTimelineProps extends PageProps {
  datesShowsObj: Common.DatesShowsObj;
  showsKeyed: Common.ShowsKeyed;
  contentByType: {
    live_recording: Common.ContentChildMdx[];
    live_video: Common.ContentChildMdx[];
    photo: Common.ContentChildMdx[];
    polaroid: Common.ContentChildMdx[];
    poster: Common.ContentChildMdx[];
    text: Common.ContentChildMdx[];
  };
  contentByCity: {
    [city: string]: Common.ContentChildMdx[];
  };
}

const ShowTimeline: React.FC<ShowTimelineProps> = props => {
  const {
    datesShowsObj,
    showsKeyed,
    location,
    contentByType,
    contentByCity,
  } = props;

  const pathnameParts = location.pathname.split('/');
  pathnameParts.pop();
  const timelineDatePath = pathnameParts.join('/');

  useRandomBgColor();

  const [show, setShow] = useState<ShowObj>({
    show: {},
    slug: string,
    content: [],
  });
  const [selectedDate, setSelectedDate] = useState<Common.DateObj>({
    year: '',
    month: '',
    date: '',
  });
  const [currContent, setCurrContent] = useState<Common.ContentChildMdx | null>(
    null
  );

  useEffect(() => {
    const currContentSlug = new URLSearchParams(window.location.search).get(
      'content'
    );

    if (currContentSlug) {
      const item = show.content.find(contentItem => {
        return contentItem.slug == currContentSlug;
      });

      if (item) {
        setCurrContent(item);
      }
    }
  }, [show.content, setCurrContent]);

  const onCloseDetailsClick = () => {
    window.history.pushState(
      null,
      document.title,
      window.location.href.split('?')[0]
    );
    setCurrContent(null);
  };

  useEffect(() => {
    const showSlug = decodeURI(location.pathname.split('/').slice(-1)[0]);
    const show = showsKeyed[showSlug];
    const showDate = moment(show.frontmatter.date);

    const date = {
      year: showDate.year().toString(),
      month: showDate.month().toString(),
      date: showDate.date().toString(),
    };

    setShow({
      show: show.frontmatter,
      slug: show.slug,
      content: datesShowsObj[date.year][date.month][date.date][showSlug],
    });
    setSelectedDate(date);
  }, [location.pathname, datesShowsObj, showsKeyed]);

  const getNextShow = useCallback(
    (isNext: boolean, showSlug: string, showDate: Common.DateObj) => {
      const years = Object.keys(datesShowsObj);
      const months = Object.keys(datesShowsObj[showDate.year]);
      const dates = Object.keys(datesShowsObj[showDate.year][showDate.month]);
      const shows = Object.keys(
        datesShowsObj[showDate.year][showDate.month][showDate.date]
      );
      const showIdx = shows.indexOf(showSlug);
      const dateIdx = dates.indexOf(showDate.date);
      const monthIdx = months.indexOf(showDate.month);
      const yearIdx = years.indexOf(showDate.year);
      let newYear = showDate.year;
      let newMonth = showDate.month;
      let newDate = showDate.date;
      let newShow = showSlug;

      if (isNext) {
        if (showIdx == shows.length - 1) {
          if (dateIdx == dates.length - 1) {
            if (monthIdx == months.length - 1) {
              if (yearIdx == years.length - 1) {
                return;
              } else {
                newYear = years[yearIdx + 1];
                newMonth = Object.keys(datesShowsObj[newYear])[0];
                newDate = Object.keys(datesShowsObj[newYear][newMonth])[0];
                newShow = Object.keys(
                  datesShowsObj[newYear][newMonth][newDate]
                )[0];
              }
            } else {
              newMonth = months[monthIdx + 1];
              newDate = Object.keys(datesShowsObj[newYear][newMonth])[0];
              newShow = Object.keys(
                datesShowsObj[newYear][newMonth][newDate]
              )[0];
            }
          } else {
            newDate = dates[dateIdx + 1];
            newShow = Object.keys(datesShowsObj[newYear][newMonth][newDate])[0];
          }
        } else {
          newShow = shows[showIdx + 1];
        }
      } else {
        if (showIdx == 0) {
          if (dateIdx == 0) {
            if (monthIdx == 0) {
              if (yearIdx == 0) {
                return;
              } else {
                newYear = years[yearIdx - 1];
                newMonth = Object.keys(datesShowsObj[newYear]).slice(-1)[0];
                newDate = Object.keys(datesShowsObj[newYear][newMonth]).slice(
                  -1
                )[0];
                newShow = Object.keys(
                  datesShowsObj[newYear][newMonth][newDate]
                ).slice(-1)[0];
              }
            } else {
              newMonth = months[monthIdx - 1];
              newDate = Object.keys(datesShowsObj[newYear][newMonth]).slice(
                -1
              )[0];
              newShow = Object.keys(
                datesShowsObj[newYear][newMonth][newDate]
              ).slice(-1)[0];
            }
          } else {
            newDate = dates[dateIdx - 1];
            newShow = Object.keys(
              datesShowsObj[newYear][newMonth][newDate]
            ).slice(-1)[0];
          }
        } else {
          newShow = shows[showIdx - 1];
        }
      }

      return {
        slug: newShow,
        date: {
          year: newYear,
          month: newMonth,
          date: newDate,
        },
      };
    },
    [datesShowsObj]
  );

  const handleArrowClick = useCallback(
    (isNext: boolean) => {
      let nextShow = getNextShow(isNext, show.slug, selectedDate);

      while (
        nextShow &&
        datesShowsObj[nextShow.date.year][nextShow.date.month][
          nextShow.date.date
        ][nextShow.slug].length == 0
      ) {
        nextShow = getNextShow(isNext, nextShow.slug, nextShow.date);
      }

      if (nextShow) {
        navigate(`${timelineDatePath}/${nextShow.slug}`);
      }
    },
    [datesShowsObj, getNextShow, selectedDate, show.slug, timelineDatePath]
  );

  const handleKeyDown = useCallback(
    e => {
      if (!currContent) {
        if (e.key == 'ArrowRight') {
          e.preventDefault();
          handleArrowClick(true);
        } else if (e.key == 'ArrowLeft') {
          e.preventDefault();
          handleArrowClick(false);
        }
      }
    },
    [currContent, handleArrowClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  return (
    <StyledContainer>
      <StyledBackButton>
        <StyledBorder>
          <StyledBackLink to={timelineDatePath}>
            Back to Timeline
          </StyledBackLink>
        </StyledBorder>
      </StyledBackButton>
      <StyledControlsSection>
        <StyledTimelineControls>
          <StyledArrow $isLeft onClick={() => handleArrowClick(false)} />
          <StyledHeadings>
            <StyledBorder>
              <StyledShowName>{`${show.show.venue}, ${show.show.city}`}</StyledShowName>
            </StyledBorder>
            <StyledBorder>
              <StyledShowDate>
                {moment(show.show.date).format('D MMMM YYYY')}
              </StyledShowDate>
            </StyledBorder>
          </StyledHeadings>
          <StyledArrow onClick={() => handleArrowClick(true)} />
        </StyledTimelineControls>
        <StyledLine />
      </StyledControlsSection>
      <StyledDragScrollWrapper>
        <StyledScrollableBox>
          {show.content.map((item, idx) => (
            <WallObject key={idx} content={item} setContent={setCurrContent} />
          ))}
        </StyledScrollableBox>
      </StyledDragScrollWrapper>
      {currContent && (
        <Details
          content={currContent}
          onCloseClick={onCloseDetailsClick}
          contentByType={contentByType}
          contentByCity={contentByCity}
          setContent={setCurrContent}
        />
      )}
    </StyledContainer>
  );
};

export default ShowTimeline;
