import React, { useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import HighlightsWall from './HighlightsWall';

import useRandomBgColor from '../../hooks/useRandomBgColor';
import { replaceShowSlugWithObj } from '../../utils/showUtils';

interface HighlightsWrapperProps {
  data: {
    highlighted: {
      childMdx: {
        frontmatter: {
          highlighted_item?: string;
        };
      };
    };
    liveRecording?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text?: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const HighlightsWrapper: React.FC<HighlightsWrapperProps> = function (props) {
  const {
    data: {
      highlighted,
      liveRecording,
      liveVideo,
      photo,
      polaroid,
      poster,
      text,
      show,
    },
  } = props;

  const [
    highlightedItem,
    setHighlightedItem,
  ] = useState<Collections.LiveVideo | null>(null);
  const [highlightedItemSlug, setHighlightedItemSlug] = useState('');
  const [allContent, setAllContent] = useState<Common.ContentNode[]>([]);
  const [contentByType, setContentByType] = useState<Collections.ContentByType>(
    {
      live_recording: [],
      live_video: [],
      photo: [],
      polaroid: [],
      poster: [],
      text: [],
    }
  );
  const [contentByCity, setContentByCity] = useState<Collections.ContentByCity>(
    {}
  );

  const showsKeyed = useMemo(() => {
    const shows = show.edges.map(edge => edge.node.childMdx);
    return keyBy(shows, 'slug');
  }, [show]);

  useEffect(() => {
    liveRecording.edges = replaceShowSlugWithObj(
      liveRecording.edges,
      showsKeyed
    );
    liveVideo.edges = replaceShowSlugWithObj(liveVideo.edges, showsKeyed);
    photo.edges = replaceShowSlugWithObj(photo.edges, showsKeyed);
    polaroid.edges = replaceShowSlugWithObj(polaroid.edges, showsKeyed);
    poster.edges = replaceShowSlugWithObj(poster.edges, showsKeyed);
    text.edges = replaceShowSlugWithObj(text.edges, showsKeyed);

    const {
      childMdx: { frontmatter },
    } = highlighted;
    if (frontmatter.highlighted_item) {
      liveVideo?.edges.some(edge => {
        if (edge.node.childMdx.slug == frontmatter.highlighted_item) {
          setHighlightedItem(edge.node.childMdx.frontmatter);
          setHighlightedItemSlug(edge.node.childMdx.slug);
          return true;
        }
        return false;
      });
    }

    setContentByType({
      live_recording: liveRecording?.edges.map(edge => edge.node.childMdx),
      live_video: liveVideo?.edges.map(edge => edge.node.childMdx),
      photo: photo?.edges.map(edge => edge.node.childMdx),
      polaroid: polaroid?.edges.map(edge => edge.node.childMdx),
      poster: poster?.edges.map(edge => edge.node.childMdx),
      text: text?.edges.map(edge => edge.node.childMdx),
    });

    const all: Common.ContentNode[] = [];
    all.push(...(liveVideo?.edges ?? []));
    all.push(...(liveRecording?.edges ?? []));
    all.push(...(photo?.edges ?? []));
    all.push(...(polaroid?.edges ?? []));
    all.push(...(poster?.edges ?? []));
    all.push(...(text?.edges ?? []));
    setAllContent(all);
  }, [
    highlighted,
    liveRecording,
    liveVideo,
    photo,
    polaroid,
    poster,
    showsKeyed,
    text,
  ]);

  const [content, setContent] = useState<Common.ContentNode[]>([]);

  useRandomBgColor();

  useEffect(() => {
    const byCity: { [city: string]: Common.ContentChildMdx[] } = {};
    allContent.forEach(edge => {
      if (!byCity.hasOwnProperty(edge.node.childMdx.frontmatter.show.city)) {
        byCity[edge.node.childMdx.frontmatter.show.city] = [];
      }
      byCity[edge.node.childMdx.frontmatter.show.city].push(edge.node.childMdx);
    });
    setContentByCity(byCity);
    setContent(allContent.sort(() => Math.random() - 0.5));
  }, [allContent]);

  // Shuffle items and set bg color
  useEffect(() => {
    if (highlightedItemSlug) {
      const liveVideoWithoutHighlighted = liveVideo?.edges.filter(
        edge => edge.node.childMdx.slug != highlightedItemSlug
      );
      setContent(
        [
          ...(liveRecording?.edges || []),
          ...(liveVideoWithoutHighlighted || []),
          ...(photo?.edges || []),
          ...(polaroid?.edges || []),
          ...(poster?.edges || []),
          ...(text?.edges || []),
        ].sort(() => Math.random() - 0.5)
      );
    }
  }, [
    highlightedItemSlug,
    liveRecording,
    liveVideo,
    photo,
    polaroid,
    poster,
    text,
  ]);

  return (
    <HighlightsWall
      content={content}
      highlightedItem={highlightedItem}
      contentByType={contentByType}
      contentByCity={contentByCity}
    />
  );
};

export default HighlightsWrapper;
